import styled from 'styled-components';

const ZoomInfoHeaderWrapper = styled.section`
  display: flex;
  margin: 55px auto 0px;
  align-items: center;
  justify-content: space-evenly;
  height: 90vh;
  background: linear-gradient(
    178.43deg,
    #1fc2ef -50.62%,
    rgba(217, 217, 217, 0) 98.67%
  );
  background-position: center center;
  background-repeat: no-repeat;
  color: black;
  padding: 20px;

  .right-img {
    width: 48%;
    @media screen and (max-width: 420px) {
      width: 100%;
      margin-top: 20px;
    }
  }

  .btn-pr {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btn {
    align-items: center;
    background-color: #f58634ff;
    color: #ffffff;
    padding: 10px 20px;
    border: none;
    font-weight: 600;
    border-radius: 5px;
    margin-right: 20px;
    cursor: pointer;
    :hover {
      background-color: #86bc4c;
      color: black;
      transition: all 0.3s ease 0s;
    }

    @media screen and (max-width: 420px) {
      font-size: 12px;
      width: 70%;
      height: 35px;
    }

    @media screen and (max-width: 440px) {
      width: 90%;
      /* padding: 20px; */
    }
  }

  .book-demo-button {
    background-color: #f58634ff;
    color: #ffffff;
    padding: 10px 20px;
    border: none;
    font-weight: 600;
    border-radius: 5px;
    margin-right: 20px;
    cursor: pointer;
    :hover {
      background-color: #86bc4c;
      color: black;
      font-weight: 600;
      transition: all 0.3s ease 0s;
    }

    @media screen and (max-width: 420px) {
      font-size: 12px;
      width: 60%;
      height: 35px;
    }
  }

  button {
    color: white;
    background-color: #03baed;
    border: none;
    font-weight: 700;
    font-size: 18px;
    border-radius: 5px;
    width: 12rem;
    height: 56px;
    cursor: pointer;
    :hover {
      background-color: #edcd37;
      color: black;
      transition: all 0.3s ease 0s;
    }

    @media screen and (max-width: 420px) {
      margin-top: 10px;
      font-size: 12px;
      width: 60%;
      height: 50px;
    }
  }

  .text {
    width: 45%;
    padding: 2px;
    /* margin-right: 20px; */
    margin-left: 40px;
    text-align: left;

    h1 {
      font-size: 34px;
      font-weight: 700;
      margin-top: 0;
      margin-bottom: 0;
      /* line-height: 25px; */
      /* text-align: justify; */
    }
    p {
      width: 90%;
      font-size: 16px;
      text-align: left;
    }
    h3 {
      font-size: 32px;
    }
    h4 {
      font-size: 24px;
    }
    @media screen and (max-width: 768px) {
      width: 90%;
      > h1 {
        font-size: 26px;
      }
      > p {
        font-size: 12px;
      }
      margin-left: 0%;
    }
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    h1 {
      font-size: 30px;
    }
    > div {
      width: 60%;
    }
  }
  @media screen and (max-width: 420px) {
    /* width: 100%; */
    /* margin-top: 200px; */
    height: 100%;
  }
`;

export default ZoomInfoHeaderWrapper;
