import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Button from 'common/components/Button';
import ZoomInfoHeaderWrapper from './zoominfoHeader.style';
import { Link } from 'gatsby';
import BookDemoPopup from '../BookADemoForHeader'; // Import your pop-up component

const ZoomInfoHeader = () => {
  const ZoominfoPageData = useStaticQuery(graphql`
    {
      allStrapiWebsiteFormEnrichmentApiPageImage {
        nodes {
          img1 {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  `);

  const [showPopup, setShowPopup] = useState(false);

  const handleBookDemoClick = () => {
    setShowPopup(true);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  const img =
    ZoominfoPageData.allStrapiWebsiteFormEnrichmentApiPageImage.nodes[0];

  return (
    <ZoomInfoHeaderWrapper>
      <div className="text">
        <h1>
          Unlock the Full Potential of Your Website Forms with Website Form
          Enrichment API
        </h1>
        <p>
          In today's data-driven world, every piece of information you gather
          about your potential customers is invaluable. But what if you could go
          beyond just collecting email addresses and gain a comprehensive
          understanding of each contact?
        </p>
        <p>With Website Form Enrichment API, you can do just that.</p>
        <div className="btn-pr">
          <button className="btn" onClick={handleBookDemoClick}>
            Book A Demo
          </button>
          {showPopup && <BookDemoPopup onClose={handlePopupClose} />}
        </div>
      </div>

      <img className="right-img" src={`${img.img1.localFile.publicURL}`} />
    </ZoomInfoHeaderWrapper>
  );
};

export default ZoomInfoHeader;
