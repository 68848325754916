import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/contexts/DrawerContext';
import { saasModernTheme } from 'common/theme/saasModern';
import { ResetCSS } from 'common/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from 'containers/SaasModern/sassModern.style';
import Navbar from 'containers/SaasModern/Navbar';
import Banner from 'containers/Custom/Features/FeatureBannerForAff';
import Footer from 'containers/AgencyModern/Footer';
import Header from '../containers/Custom/WebsiteFormEnrichmentApis/Header';
import Content from '../containers/Custom/WebsiteFormEnrichmentApis/MainComHeading';
import Seo from 'components/seo';
import FaqSection from 'containers/Hosting/FaqForAff';
import Thanksgiving from '../containers/SaasModern/Thanksgiving';
import { Helmet } from 'react-helmet';
import Directory from '../containers/Directory/index';
import { graphql } from 'gatsby';
import G2Slider from '../containers/SaasModern/G2Badges';
import BookDemoButton from '../containers/SaasModern/ScheduleAdemo/ButtonDemo';

const FeaturePage = () => {
  return (
    <ThemeProvider theme={saasModernTheme}>
      <Fragment>
        <Seo
          title="Boost Leads & Conversions with Website Form Enrichment API | Clodura.AI"
          description="Clodura.AI's API instantly enriches your website form submissions with Full contact details, Job title, Company Affiliation, and more actionable insights. Say goodbye to inaccurate and incomplete form data!"
          robots="index, follow"
          canonical="https://www.clodura.ai/website-form-enrichment-apis/"
          keyword="Website form enrichment, Marketing automation, CRM enrichment, Data enrichment API,  AI-powered data enrichment, Lead generation, Sales intelligence"
          extraScript={`gtag('event', 'conversion', {'send_to': 'AW-10934282448/LYpPCMWy7IQYENDR790o'});`}
        />
        <Helmet></Helmet>
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
              {/* <Thanksgiving /> */}
            </DrawerProvider>
          </Sticky>
          <Header />
          <Content />
          <G2Slider />
          <Footer />
          <BookDemoButton />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};

export default FeaturePage;
