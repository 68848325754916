import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Button from 'common/components/Button';
import BookDemoPopup from '../BookDemoPopUp'; // Import your pop-up component
import ZoomInfoHeaderWrapper from './zoominfoHeader.style';
import OkIcon from 'common/assets/image/saasModern/WebApis/ok_icon.svg';
import LeftImg from 'common/assets/image/saasModern/WebApis/Left.png';
import RightImg from 'common/assets/image/saasModern/WebApis/Right.png';
import { Link } from 'gatsby';

const ZoomInfoHeader = () => {
  const ZoominfoPageData = useStaticQuery(graphql`
    {
      allStrapiWebsiteFormEnrichmentApiPageImage {
        nodes {
          img1 {
            localFile {
              publicURL
            }
          }
          img2 {
            localFile {
              publicURL
            }
          }
          img3 {
            localFile {
              publicURL
            }
          }
          img4 {
            localFile {
              publicURL
            }
          }
          img5 {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  `);

  const img =
    ZoominfoPageData.allStrapiWebsiteFormEnrichmentApiPageImage.nodes[0];

  const listStyle = {
    listStylePosition: 'inside', // This style moves the bullets inside the list items
  };

  const [showPopup, setShowPopup] = useState(false);

  const handleBookDemoClick = () => {
    setShowPopup(true);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  return (
    <>
      <ZoomInfoHeaderWrapper>
        <img className="right-img" src={`${img.img2.localFile.publicURL}`} />
        <div className="text">
          <h2>Enrich Your Website Forms with Powerful Data Insights</h2>
          <p>
            Our API seamlessly integrates with your website forms, transforming
            them into data-gathering powerhouses. By simply providing an email
            address, you'll instantly receive a wealth of information about that
            contact, including:
          </p>

          <p>
            <img src={OkIcon} /> Full contact details
          </p>
          <p>
            <img src={OkIcon} /> Job title
          </p>
          <p>
            <img src={OkIcon} /> Company affiliation
          </p>

          <p>
            This enriched data empowers you to make informed decisions about
            your marketing efforts, sales strategies, and customer engagement.{' '}
          </p>
        </div>
      </ZoomInfoHeaderWrapper>
      <ZoomInfoHeaderWrapper>
        <div className="text">
          <h2>Enhance Your Lead Qualification</h2>
          <p>
            Accurate contact information is just the beginning. Website Form
            Enrichment API goes further by providing you with insights into your
            leads' employment status and company affiliation. This valuable data
            allows you to:
          </p>

          <p>
            <img src={OkIcon} /> Identify high-potential leads
          </p>
          <p>
            <img src={OkIcon} /> Target specific industries and job roles
          </p>
          <p>
            <img src={OkIcon} /> Personalize your outreach and messaging
          </p>

          <p>
            By understanding your leads better, you can nurture them more
            effectively and convert them into loyal customers.
          </p>
        </div>
        <img className="right-img" src={`${img.img3.localFile.publicURL}`} />
      </ZoomInfoHeaderWrapper>

      <ZoomInfoHeaderWrapper>
        <img className="right-img" src={`${img.img5.localFile.publicURL}`} />
        <div className="text">
          <h2>Unlock the True Potential of Your Website Forms</h2>
          <p>
            Website Form Enrichment API is the key to unlocking the full
            potential of your website forms. With our API, you can:
          </p>

          <p>
            <img src={OkIcon} /> Gather richer and more comprehensive data about
            your contacts
          </p>
          <p>
            <img src={OkIcon} /> Enhance your lead qualification process
          </p>
          <p>
            <img src={OkIcon} /> Save time and streamline your workflow
          </p>

          <p>
            This enriched data empowers you to make informed decisions about
            your marketing efforts, sales strategies, and customer engagement.
          </p>
        </div>
      </ZoomInfoHeaderWrapper>
      <ZoomInfoHeaderWrapper>
        <div className="text">
          <h2>Streamline Your Workflow and Save Time </h2>
          <p>
            Stop wasting time manually researching and verifying contact
            information. Website Form Enrichment API automates this process,
            freeing you up to focus on more strategic tasks. Our API seamlessly
            integrates with your existing CRM and marketing automation systems,
            ensuring a smooth and efficient workflow.
          </p>
        </div>
        <img className="right-img" src={`${img.img4.localFile.publicURL}`} />
      </ZoomInfoHeaderWrapper>
      <ZoomInfoHeaderWrapper>
        <div className="bottom-div">
          <h2>Join the Data Revolution Today</h2>
          <div className="main-div">
            <img src={LeftImg} />
            <p>
              Integrate Website Form Enrichment API into your marketing workflow
              and experience the transformative power of data-driven customer
              engagement. Contact us today to unleash the potential of your
              leads and propel your marketing success to new heights.
            </p>

            <img src={RightImg} />
          </div>

          <button className="btn" onClick={handleBookDemoClick}>
            Book A Demo
          </button>
          {showPopup && <BookDemoPopup onClose={handlePopupClose} />}
        </div>
      </ZoomInfoHeaderWrapper>
    </>
  );
};

export default ZoomInfoHeader;
